import React from 'react'
import Container from '@material-ui/core/Container'
import {Helmet} from 'react-helmet'
import { useStyles } from './styles'
import MainImage from '../../assets/images/норії-інпрофіт.jpg'


//other products
import SectionProducts from '../Home/sectionProducts'

export default function Noriyi() {
    const classes = useStyles()

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>INPROFIT - Виготовлення норії під замовлення.</title>
                <link rel="canonical" href="https://inprofit.com.ua/noriyi" />
                <meta name="description" content="Виготовлення та монтаж норії під замовлення в Україні." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr}/>
                <div className={classes.imageAndTitle}>
                    <img className={classes.mainImage} src={MainImage} alt="Норії. Виготовлення та встановлення Україна - ІнпроФіт"/>
                    <h1 className={classes.productTitle}>Виробництво норій</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                    <p>Норії - механізми, що застосовуються для транспортування на 
                        певну висоту сипучих матеріалів. Норії застосовуються 
                        практично у всіх галузях промисловості. У сільському 
                        господарстві норії успішно використовуються для переміщення 
                        зерна та продуктів його переробки.</p>
                    <p>Принцип роботи норії ковшового полягає в наступному: за рахунок 
                        безперервно руху ківшів, закріплених на стрічковому (або 
                        ланцюговому) конвеєрі виробляється підйом матеріалу. 
                        Сипучий матеріал підхоплюється ківшами в нижньому секторі 
                        конвеєрної стрічки, рухається вертикально і розвантажується 
                        в верхньому секторі через патрубок. Далі вниз рухаються 
                        перекинуті ківші. Висота підйому за технологією не перевищує 
                        30 метрів.</p>
                    <p>Висота норії утворюється набором верхньої частини (головка), 
                        нижньої частини (башмак), прохідних і ремонтних секцій. 
                        З'єднання всіх вузлів норії болтове. За конструкцією головки 
                        привід кріпиться безпосередньо на самій норії і не вимагає 
                        додаткових майданчиків для кріплення. Всі норії укомплектовані 
                        механізмами зупинки зворотного ходу.</p>
                    <p>Натяг стрічки з ківшами відбувається в башмаку за допомогою 
                        двох натяжних гвинтів. Тяговим органом стрічкової норії є 
                        ремінь гумотканинний на якому встановлені ківші з зносостійкої 
                        харчової пластмаси.</p>
                    <p>Привід складається з циліндричного редуктора вітчизняного або 
                        імпортного виробництва і електродвигуна, або мотор-редуктора 
                        імпортного виробництва. Редуктор з пустотілим вихідним валом 
                        посаджений на вал приводного барабана і передає обертання на 
                        нього безпосередньо без муфти, чи ременів.</p>
                    <p>Норії виготовлюються в двох варіантах покриття:</p>
                    <ul>
                        <li>пофарбований фарбою;</li>
                        <li>гаряче цинкування не нижче 250г / м².</li>
                    </ul>
                    <p>На сьогоднішній день ми виробляємо норії продуктивністю від 5 до 50 тонн на годину.</p>
                </Container>
                <SectionProducts/>
            </Container>
        </div>
    )
}