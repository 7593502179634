import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import colors from '../../../assets/colors'

import bgImage from '../../../assets/images/модернізація-зерно-сушарок-Україна.jpg'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: colors.main,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        minHeight: '300px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,.2)',
            left: 0,
            top: 0,
        },
    },
    header:{
        color: colors.mainDark,
        backgroundColor: 'rgba(255,255,255,6)',
        zIndex: 0,
        maxWidth: '60%',
        padding: '1rem 2rem',
        marginBottom: '2rem',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '80%',
        },
    },
    content:{
        display: 'flex',
        padding: '2rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    button:{
        minWidth: '300px',
        backgroundColor: colors.main,
        color: colors.white,
        fontSize: '1.2rem',
        marginLeft: '1rem',
        '&:hover': {
            backgroundColor: colors.mainDark,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '2rem',
            minWidth:' auto',
        },
    },
}))

export default function SectionImage() {

    const classes = useStyles()

    return (
        <>
            <Container size='lg' className={classes.root} style={{ backgroundImage: `url( ${bgImage} )` }} >
                <h2 className={classes.header}>
                    Модернізація зерносушарок та переведення їх на альтернативні види палива
                </h2>
            </Container>
            <Container size='lg' >
                <div className={classes.content}>
                    <Typography>
                        В сучасних умовах перехід на альтернативні види палива є досить актуальним.
                        Маючи досвід роботи по виробництву і модернізації устаткування для переробки 
                        зерна  ми пропонуємо повний перелік послуг, пов'язаний з переобладнанням 
                        існуючих зерносушарок на альтернативні види палива.
                    </Typography>
                    <Button href='/modernizaciya' variant="contained" className={classes.button}>
                        Детальніше
                    </Button>
                </div>
                <hr style={{borderColor:colors.main}}/>
            </Container>
        </>
    )
}
