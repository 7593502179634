import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

//colors
import colors from '../../../assets/colors'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundPosition: 'center bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        padding: '3rem 0',
        position: 'relative',
        '&:before': {
            content: '""',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            position: 'absolute',
            backgroundColor: colors.black,
            opacity: '.6',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    contentHolder: {
        backgroundColor: 'rgba(33,150,243, .5)',
        width: '40%',
        padding: '3rem',
        paddingTop: '7rem',
        color: colors.white,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
            padding: '2rem',
            paddingTop: '7rem'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            backgroundColor: 'rgba(33,150,243, .7)',
        },
    },
    title: {
        position: 'absolute',
        fontSize: '60px',
        top:'20px',
        opacity: '.7',
        textTransform: 'uppercase',
        fontWeight: '900',
        right: '2rem',
        '&:after':{
            content: '""',
            position: 'absolute',
            top: '22px',
            right: '-60px',
            width: '60px',
            height: '43px',
            backgroundColor: colors.white,
            opacity: '1',
            display: 'block',
            [theme.breakpoints.down('xs')]: {
                right: '-40px',
                top: '14px',
                width: '40px',
                height: '29px',
            },
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        },
    },
    owner: {
        fontSize: '1.5rem',
        marginBottom: '10px',
    },
    contactsData : {
        fontSize: '1.2rem',
        margin: '10px 0',
        paddingLeft: '2rem',
        '& a' : {
            color: colors.white,
            textDecoration: 'none',
        }
    },
}))

export default function HomeContacts( props ) {
    const classes = useStyles()
    const {bgImage} = props
    return (
        <div className={classes.root} style={{ backgroundImage: `url(${bgImage})`}}>
            <Container className={classes.container}>
                <div className={classes.contentHolder}>
                    <Typography className={classes.title}>
                        Контакти
                    </Typography>
                    <Typography className={classes.owner}>
                        Трухін Сергій Миколайович
                    </Typography>
                    <u>Телефони:</u>
                    <Typography className={classes.contactsData}>                     
                        <a href="tel:+380973881262">097-388-12-62</a>
                    </Typography>
                    <Typography className={classes.contactsData}>
                        <a href="tel:+380991014200">099-101-42-00</a>
                    </Typography>
                    <u>E-mail:</u>
                    <Typography className={classes.contactsData}>
                        <a href="mailto:inprofit@i.ua">inprofit@i.ua</a>
                    </Typography>
                </div>
            </Container>
        </div>
    )
}
