import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container';
import colors from '../../assets/colors'

const styles = {
    footer:{
        backgroundColor: colors.main,
        minHeight: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    copyright: {
        textAlign: 'center',
        width: '100%',
        color: colors.white,
        fontSize: '.8rem',
        lineHeight: '1.5rem',
    },
    footerContent: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}

export class Footer extends Component {
    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <footer className={classes.footer}>
                    <Container fixed>
                        <p className={classes.copyright}>&copy; ІНПРОФІТ <br/> Високопродуктивні системи сушіння зерна. <br/> Виготовлено в Україні.</p>
                    </Container>
                </footer>
            </React.Fragment>
        )
    }
}

Footer.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(styles)(Footer)