import React from 'react';
import Header from './components/header'
import Footer from './components/footer'

import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {makeStyles} from '@material-ui/core/styles'

import ScrollTop from './components/scroollToTop'
import colors from './assets/colors'
import fonts from './assets/style'

import { BrowserRouter as Router , Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import FAQ from './pages/FAQ'
import About from './pages/About'
import ContactUs from './pages/ContactUs'
import NoMatch from './pages/404'
import KonveernaSusharka from './pages/products/konveerna-susharka'
import KolonnaSusharka from './pages/products/kolonna-susharka'
import Teplogenerator from './pages/products/teplogenerator'
import Modernizaciya from './pages/products/modernizaciya'
import Metalokonstrukciyi from './pages/products/metalokonstrukciyi'
import Noriyi from './pages/products/noriyi'
import SusharkaFruktiv from './pages/products/susharka-fruktiv'

import { createBrowserHistory } from 'history';

const useStyles = makeStyles( (theme) => ({
  toTopBtn : {
    backgroundColor: colors.main, 
    color: colors.white
  },
  app:{
    fontFamily: fonts.fontFamily,
    '& section': {
      margin: '5rem 0',
      [theme.breakpoints.down('sm')]: {
        margin: '3rem 0',
      },
    },
  },
  hidden:{
    opacity: 0,
    transition: 'all 3s ease-out',
    zIndex: -1
  },
}))

function App() {
  const classes = useStyles()
  return (
    <Router history={createBrowserHistory}>
      <div className={classes.app}>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/konveerna-zernosusharka" component={KonveernaSusharka} />
          <Route exact path="/kolonna-zernosusharka" component={KolonnaSusharka} />
          <Route exact path="/teplogenerator" component={Teplogenerator} />
          <Route exact path="/modernizaciya" component={Modernizaciya} />
          <Route exact path="/metalokonstrukciyi" component={Metalokonstrukciyi} />
          <Route exact path="/noriyi" component={Noriyi} />
          <Route exact path="/susharka-fruktiv" component={SusharkaFruktiv} />
          <Route component={NoMatch} />
        </Switch>
        <ScrollTop>
          <Fab style={{backgroundColor:colors.main, color: colors.white}} size="large" aria-label="scroll back to top">
            <KeyboardArrowUpIcon size="large"/>
          </Fab>
        </ScrollTop>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
