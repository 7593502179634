import { makeStyles } from '@material-ui/core/styles'
import colors from '../../assets/colors'

export const useStyles = makeStyles((theme) => ({
    root: {},
    hr: {
        marginTop: 0,
        borderColor: colors.main,
        width: '100%',
    },
    container: {

    },
    mainImage: {
        maxWidth: '100%',
        width: '50%',
        padding: '1rem 0 2rem',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
    imageAndTitle: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '435px',
    },
    productTitle: {
        backgroundColor: colors.main,
        color: colors.white,
        position: 'absolute',
        top: '65%',
        left: '10%',
        padding: '1rem 2rem',
        boxShadow: '-6px 8px 16px 0px rgba(33,150,243, 0.5)',
        borderRight: `7px solid ${colors.white}`,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: colors.white,
            color: colors.main,
            position: 'initial',
            borderRight: `none`,
        },
    },
    contentSection: {

    },
    contentTable: {
        maxWidth: '650px',
        margin: '2rem auto',
    },
}))