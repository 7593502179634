import React from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import LogoIcon from '../../components/logoIcon'


const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        margin: '4rem 0',
        justifyContent: 'center',
        alignItems:'center',
        height: '70vh'
    },
    headline:{
        textAlign: 'center',
        fontSize: '4rem',
        letterSpacing: '.2rem'
    },
    subline:{
        textAlign: 'center',
    }
})) 

function NoMatch () {
    const classes = useStyles()
    return (
        <Container>
            <div className={classes.root }>
                <div>
                    <h1 className={classes.headline}>4<LogoIcon width={50} />4</h1>
                    <h2 className={classes.subline}>По Вашому запиту <br/> нічого не знайдено...</h2>
                </div>
            </div>
        </Container>
    )
}

export default NoMatch
