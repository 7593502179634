import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
// colors
import colors from '../../assets/colors'

import Preloader from '../../components/preloader'


const useStyles = makeStyles((theme) => ({
    heroContainer: props =>({
        backgroundColor: colors.main,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundImage: `url( ${props.backgroundImage} )`,
        minHeight: '650px',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '0 1rem',
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: '450px',
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,.6)',
            left: 0,
            top: 0,
        },
    }),
    h1: {
        textAlign: 'center',
        width: '100%',
        maxWidth:'1400px',
        margin: '0 auto',
        color: colors.white,
        fontSize: '4.4rem',
        zIndex:2,
        [theme.breakpoints.down('md')]: {
            fontSize: '2.8rem',
         },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.4rem',
        },
    },
    h2: {
        textAlign: 'right',
        maxWidth: '800px',
        marginTop: '4rem',
        color: colors.white,
        backgroundColor: 'rgba(76,140,74,.5)',
        padding: '1rem 2rem',
        fontSize: '2rem',
        float: 'right',
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.4rem',
         },
    },
    h2holder : {
        zIndex:2,
        margin: '0 auto',
        width:'100%',
        maxWidth: '1200px',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
         },
    },
}))  

export default function Hero (props) {
    const {bgImage, bgImagePre, h1Data, h2Data} = props
    const [docReady, setDocReady] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(bgImagePre)
    const classes = useStyles({backgroundImage})
    
    const imageForHero1 = new Image();
    imageForHero1.src = bgImagePre;
    imageForHero1.onload = () => {
        setDocReady(true)
    }
    const imageForHero2 = new Image();
    imageForHero2.src = bgImage;
    imageForHero2.onload = () => {
        setBackgroundImage(bgImage)
    }

    return (
        <div>
            {!docReady && <Preloader animation={true}/>}
            <div className={classes.heroContainer}>
                <h1 className={classes.h1}>{h1Data}</h1>
                <div className={classes.h2holder}>
                    {h2Data && <h2 className={classes.h2}>{h2Data}</h2> }
                </div>
                
            </div>
        </div>
    )
}