import React, { useState } from 'react'
import Hero from '../../components/hero'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import BgHero from '../../assets/images/contact-us.jpg'
import BgHeroPre from '../../assets/images/contact-us_w_200.jpg'

import colors from '../../assets/colors'

const styles = makeStyles( (theme) => ({
    formComtainer: {
        marginBottom: '3rem',
        marginTop: '3rem',
    },
    formTitle:{
        textAlign: 'center',
        marginBottom: '3rem',
    },
    subLine: {
        marginTop: '1rem',
        paddingTop: '1rem',
        textAlign: 'right',
        fontSize: '.8rem',
        borderTop: `1px solid ${colors.main}`,
    },
}))

const formStyles = makeStyles( (theme) => ({
    formCTA: {
        padding: '1rem 3rem',
        borderColor: colors.main,
        color: colors.main,
        transition: 'all .2s ease-in-out',
        '&:hover, &:active':{
            backgroundColor: colors.main,
            color: colors.white,
        }
    }
}))

const formSubmitStyles = makeStyles( (theme) => ({
    root: {
        padding: '1rem 3rem',
    }
})) 


const Form = (props) => {
    
    const [ firstName, setFirstName ] = useState('')
    const [ phoneNumber, setPhoneNumber ] = useState('')
    const [ message, setMessage ] = useState('')
    const formClasses = formStyles()
    
    const handleClick = () => {
        const data = {firstName,phoneNumber,message}
        const hookUrl = `https://hook.integromat.com/rta4ydc4xm48mpwjyzjy3vr08x0sf6w7`
        fetch(hookUrl,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.ok && props.handleFormSubmit())        
    }
    
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField id="firstName" value = {firstName} onChange={(e) => setFirstName(e.target.value)} label="Ваше ім'я" variant="outlined" fullWidth autoComplete="fname" required/>
                </Grid >
                <Grid item xs={12} sm={6}>
                    <TextField id="phoneNumber" value = {phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} label="Номер телефону" variant="outlined" fullWidth autoComplete="phone" required/>
                </Grid>
                <Grid item xs={12}>
                    <TextField id="comments" label="Питання, яке Вас цікавить" onChange={(e) => setMessage(e.target.value)} variant="outlined" fullWidth multiline={true} rows={4} rowsMax={4}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify={'flex-end'}>
                        <Button className={formClasses.formCTA} variant="outlined" onClick={handleClick}> Надіслати </Button>
                    </Grid>
                </Grid>
            </Grid >
         </form >
    )
}

const FormSubmited = () => {
        const formSubmitClasses = formSubmitStyles()
     return (
     <Grid className={formSubmitClasses.root}>
        <p>
            Ваш запит відправлено нашому менеджеру на розгляд, і 
            найближчим часом ми зв'яжемось з Вами.
        </p>
        <p>Дякуємо за звернення!</p>
    </Grid>)
}


export default function ContactUs () { 
    const pageStyles = styles()
    const [ formSubmit, setFOrmSubmit ] = useState(false)
    
        return (
            <div>
                <Hero 
                    h1Data={`Наші контакти`}
                    bgImage={BgHero}
                    bgImagePre={BgHeroPre}
                />

                <Container maxWidth="md" className={pageStyles.formComtainer}>
                    <h2 className={pageStyles.formTitle}>Форма зворотнього зв'язку</h2>
                    {formSubmit ? <FormSubmited /> : <Form handleFormSubmit={()=> setFOrmSubmit(true)}/>}
                    <p className={pageStyles.subLine}>
                        Заповніть форму і надлішліть її. Протягом робочого дня наш менеджер зв'яжеться з Вами для надання відповідних консультацій
                        з приводу нашої продукції, або з інших питань окреслених у Вашому повідомленні.
                    </p>
                </Container>
                
            </div>
        )
}