import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import colors from '../../assets/colors'

const styles = theme => ({
    menuContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            left: '100%',
            top: 0,
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: '3rem 0',
            margin: '0',
            backgroundColor: colors.main,
            zIndex: 9999,
            transition: 'left .3s ease-in',
            '&.show':{
                left: 0,
            },
          },
    },
    menuContainerItem: {
        paddingLeft: '2rem',
        listStyle: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '1rem 0 1rem 5rem',
            textAlign: 'left',
            fontSize: '1.5rem',
            fontWeight:'200',
            zIndex: 10000,
        },
        '& a':{
            color: colors.main,
            textTransform: 'uppercase',
            textDecoration: 'none',
            cursor: 'pointer',
            fontWeight: '900',
            padding: '7px 1px',
            borderBottom: '1px solid transparent',
            transition: 'all .2s ease-in',
            '&:hover' : {
                borderBottom: `1px solid ${colors.main}`,
                paddingBottom: '1px',
            },
            [theme.breakpoints.down('sm')]: {
                color: colors.white,
                fontWeight: '400',
                border: 'none',
                '&:hover' : {
                    border: 'none',
                    paddingBottom: '7px',
                },
            },
            '&:before':{ /// for some reason this doesn't work (((( small arrow right
                [theme.breakpoints.down('sm')]: {
                    content: '"\\2192"',
                    marginRight: '1rem',
                    fontSize: '1.3rem',
                },
            },
        },
    },
    menuMobileButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
           display: 'flex',
           height: '100%',
        },
        '&.show': {
            '& .menuIcon':{
                backgroundColor: colors.white,
                '&::before, &::after':{
                    backgroundColor: colors.white,
                }
            },
        },
        '& .menuIcon': {
            position: 'relative',
            backgroundColor: colors.main,
            width: '2rem',
            height: '.2rem',
            margin:'auto',
            zIndex:99999,
            transition: 'all 0.3s ease-in',
            '&::before, &::after':{
                content: '""',
                position: 'absolute',
                left: 0,
                width: '2rem',
                height: '.2rem',
                background: colors.main,
                transition: 'all 0.3s ease-in',
            },
            '&::before':{
                top: '-.5rem',
            },
            '&::after':{
                top: '.5rem',
            },
        },
    },
})


export class Menu extends Component {
    state = {
        showMenu : false
    }
    showMenuToggle = () => {
        this.setState( prevState => ({showMenu: !prevState.showMenu}))
    }
    hideMenuOnClickLink = () => { 
        this.setState({showMenu: false})
    }
    render() {
        const {classes} = this.props
        const showMenuClass = this.state.showMenu && 'show'
        return (
            <div>
                <ul className={`${classes.menuContainer} ${showMenuClass}`}>
                    <li className={classes.menuContainerItem}>
                        <Link onClick={this.hideMenuOnClickLink} to="/">Головна</Link>
                    </li>
                    <li className={classes.menuContainerItem}>
                        <Link onClick={this.hideMenuOnClickLink} to="/about">Про нас</Link>
                    </li>
                    <li className={classes.menuContainerItem}>
                        <Link onClick={this.hideMenuOnClickLink} to="/faq">Питання</Link>
                    </li>
                    <li className={classes.menuContainerItem}>
                        <Link onClick={this.hideMenuOnClickLink} to="/contact-us">Контакти</Link>
                    </li>
                </ul>
                <div className={`${classes.menuMobileButton} ${showMenuClass}`}>
                    <div className="menuIcon" onClick={this.showMenuToggle}></div>
                </div>
                
            </div>
        )
    }
}

Menu.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(styles)(Menu)
