import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {Accordion} from '@material-ui/core';
import {AccordionSummary} from '@material-ui/core';
import {AccordionDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    root:{
        margin: '2rem 0'
    },
})

class AccordionItem extends Component {

    render() {
        const {classes} = this.props
        
        return (
            <div className={classes.root}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                    >
                        {this.props.question ? this.props.question : ` - `}
                    </AccordionSummary>
                    <AccordionDetails>
                        {this.props.answer ?  <div dangerouslySetInnerHTML={{__html: this.props.answer}} /> :  ` ` }
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
}


AccordionItem.propTypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(styles)(AccordionItem)