import React from 'react'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import MainImage from '../../assets/images/колонна-зерносушарка.jpg'

//other products
import SectionProducts from '../Home/sectionProducts'

import { Helmet } from 'react-helmet'

export default function KolonnaSusharka() {
    const classes = useStyles()
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Модульна зерносушарка - INPROFIT</title>
                <link rel="canonical" href="https://inprofit.com.ua/kolonna-zernosusharka" />
                <meta name="description" content="Зерносушарки 'INPROFIT' є продукцією власного виробництва з матеріалів високої якості та замінних частин, які є доступними на ринку України." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr}/>
                <div className={classes.imageAndTitle}>
                    <img className={classes.mainImage} src={MainImage} alt="Колонна зерносушарка від ІнпроФіт Україна"/>
                    <h1 className={classes.productTitle}>Колонна зерносушарка</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                    <p>Раніше багато аграріїв для сушки сільськогосподарських культур вибирали зарубіжні мобільні зерносушарки. 
                        Основна причина була у якості технологій і відсутності аналогів на нашому ринку.</p>
                    <p>На сьогоднішній день багатьом вже не досить якості і продуктивності мобільних зерносушарок і вони 
                        переходять на стаціонарні сушарки.</p>
                    <p>При виборі потужного і якісного устаткування фермери зупиняються на шахтних та модульних – колонних 
                        зерносушарках, але вибір виробника вже проходить по інших критеріях.</p>
                    <p>Найбільші виробники закордонних зерносушарок не можу сьогодні конкурувати за цінами на свої технології. 
                        Крім того, основні виробники зерносушарок в Україні за декілька десятиліть змогли вивести якість 
                        устаткування на новий рівень. І сьогодні такі зерносушарки анітрохи не поступаються польським, 
                        французьким і американським зразкам. Якщо врахувати вартість устаткування, запчастин і можливості 
                        компанії по технічному і сервісному обслуговуванню, то український виробник цих зерносушарок виграє 
                        за усіма критеріями.</p>
                    <p>Приватне Мале Підприємство"ІНПРОФІТ  пропонує Вам співпрацю на вигідних умовах щодо виготовлення по 
                        Вашому замовленню зерносушарки високої якості, за короткий термін.</p>
                    <p>У комплектації модульної зерносушарки "ІНПРОФІТ" входять елементи і системи (металоконструкції, 
                        теплогенератори, вентилятори), які розроблені конструкторами підприємства.</p>
                    <p>Зерносушарки від «ІНПРОФІТ» призначені для важливого етапу в післяжнивній обробці зерна. Наше устаткування 
                        збільшує термін зберігання урожаю без псування і втрат, адже правильна сушка виключає самонагрівання, 
                        появу плісняви і розмноження комах-шкідників.</p>
                    <p>Наші зерносушарки шахтного і колонкового типу адаптовані під особливості кліматичної зони та ідеально 
                        підходять для сушки зерна з показниками вологості 18-24.</p>
                    <p>Моделі сушарок виробництва «ІНПРОФІТ» мають малу металоємність і економічні у витраті енергетичних 
                        ресурсів. Технічні параметри машин дозволяють отримати на виході живе зерно, що не травмоване і не 
                        втратило внаслідок перегрівання свою схожість.</p>
                    <p>Виготовляються зерносушарки із оцинкованої сталі (за бажанням замовника - нержавіюча сталь).</p>
                    <p>Завдяки широкому модельному ряду, користувачі в нашій компанії можуть підібрати оптимальну конфігурацію 
                        зерносушильного устаткування. Такі установки можуть працювати з різними видами палива (газ, дизель, 
                        дрова, відходи тощо), що дуже важливо для споживачів і підвищує економічний ефект від застосування 
                        наших агрегатів.</p>
                    <p>Так як, в основному, наша продукція виготовляється під індивідуальне замовлення, то в кожному окремому 
                        випадку враховуються побажання замовника по всіх технічних характеристиках виробу.</p>
                </Container>
                <SectionProducts/>
            </Container>
            
        </div>
    )
}