import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

//colors
import colors from '../../../assets/colors'
//images
import image1 from '../../../assets/images/сушка-для-фруктів.png'
import image2 from '../../../assets/images/конвеєрна-сушка.png'
import image3 from '../../../assets/images/норії.png'
import image4 from '../../../assets/images/металоконструкції.png'
const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        marginBottom: '4rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection:'column',
            alignItems:'center',
        },
    },
    itemContainer: {
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        width: '25%',
        margin: '1rem',
        minHeight: '250px',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    itemTitle:{
        textAlign: 'center',
        color: colors.main,
    },
    itemImage:{
       width: '100%', 
    },
    itemText: {
        fontSize: '.8rem',
    },
    itemButton: {
        float: 'right',
        color: colors.main,
        border: `1px solid ${colors.main}`,
        transition: 'all .3s ease-in-out',
        '&:hover':{
            backgroundColor: colors.mainDark,
            color: colors.white
        },
    },
})) 

const products = [
    {id: 'ipf-003',title: 'Сушарка для фруктів', image: image1 ,alt: 'Сушарка для фруктів',url: "/susharka-fruktiv",desc:"Сушарка для овочів і фруктів на альтернативному паливі  призначена для сушіння овочів, фруктів, ягід, грибів, лікарських трав, а також інших продуктів рослинного походження."},
    {id: 'ipf-004',title: 'Сушарка конвеєрна', image: image2 ,alt: 'Сушарка конвеєрна',url: "/konveerna-zernosusharka",desc:"Сушарка конвеєрна застосовується для сушки  квіток коноплі та листків. Але також може використовуватись для сушки і інших трав’яних рослин та фруктів."},
    {id: 'ipf-005',title: 'Норії', image: image3 ,alt: 'Норії',url: "/noriyi",desc:"Основне призначення норій на елеваторах — вертикальне переміщення зерна. Вони мають компактну конструкцію і здатні підіймати вантаж на десятки метрів вгору"},
    {id: 'ipf-006',title: 'Металоконструкції', image: image4 ,alt: 'Металоконструкції',url: "/metalokonstrukciyi",desc:"Металеві конструкції по області застосування можна розділити на машинобудівні конструкції і конструкції будівель і споруд."},
]

export default function SectionProducts(){
    const classes = useStyles()
    const listItems = products.map(item => <div key={item.id} className={classes.itemContainer}>
            <h3 className={classes.itemTitle}>{item.title}</h3>
            <img className={classes.itemImage} src={item.image} alt={item.alt}></img>
            <p className={classes.itemText}>{item.desc}</p>
            <Button className={classes.itemButton} variant="outlined" size="medium" href={item.url}> Більше... </Button>
        </div> )
    return (
            <Container size="lg" className={classes.root}>
                {listItems}
            </Container>
    )
}
