import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import LogoIcon from '../logoIcon'

const useStyles = makeStyles( () => ({
    preloader:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: '#fff',
      zIndex: 9,
      opacity: 1,
      top: 0,
      left: 0
    }
  }))      
          
export default function Preloader(props) {
    const classes = useStyles()
    const { width, animation } = props
    return (
        <div className={classes.preloader}>
          <LogoIcon width={width} animation={animation}/>
        </div>
    )
}