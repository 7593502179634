import React from 'react'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import MainImage from '../../assets/images/сушарка-для-фруктів-інпрофіт.jpg'

//other products
import SectionProducts from '../Home/sectionProducts'

import { Helmet } from 'react-helmet'

export default function SusharkaFruktiv() {
    const classes = useStyles()
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Сушарка для фруктів на дровах - INPROFIT</title>
                <link rel="canonical" href="https://inprofit.com.ua/susharka-fruktiv" />
                <meta name="description" content="Універсальна Сушарка для фруктів виготовляє сушню вищої якості не тільки з фруктів, але і практично з будь-яких інших продуктів." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr} />
                <div className={classes.imageAndTitle}>
                    <img className={classes.mainImage} src={MainImage} alt="Колонна зерносушарка від ІнпроФіт Україна" />
                    <h1 className={classes.productTitle}>Сушарка для фруктів</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                    <p>Сушарка для овочів і фруктів на альтернативному паливі  призначена для сушіння овочів, фруктів, ягід, грибів, лікарських трав, а також інших продуктів рослинного походження. Ще сушарку можна використовувати, як обігрівач примі­щення.</p>
                    <p>Саме сушка залишається одним з найбільш поширених способів заготівлі фруктів на зиму. Сушка для фруктів видаляє з продуктів велику кількість вологи, і завдяки цьому вони здатні зберігатися тривалий термін. Сушарка для фруктів має просту конструкцію. Для сушіння використовується найпростіше, до чого тільки можна додуматися - гаряче повітря.</p>
                    <p>Сушарка виробництва «ІНПРОФІТ» є конвективною сушаркою камерного типу періодичної дії, з живленням від загальної мережі 220В, 50Гц, і розрахована на безперервну роботу протягом всього сезону переробки сільгосппродукції).</p>
                    <p>Сушарка призначена для експлуатації в приміщеннях з вентиляційною системою або під навісом, при температурі навколишнього середовища від -5 до +35 ° С і відносній вологості до 80%.</p>
                    <p>Принцип дії сушарки СФ-100.</p>
                    <p>Сушильний агент (повітря) нагрівається до заданої температури, що відображено на екрані. За допомогою вентилятору розігріте повітря рівномірно обдуває продукти, які розміщені на піддонах на стелажі і відбирає від них зайву вологу.</p>
                    <p>Управління сушаркою здійснюється в ручному режимі.</p>
                    <p>Особливості сушки фруктів</p>
                    <ul>
                        <li>Універсальність. Сушарки для фруктів виробляють сушню вищої якості не тільки з фруктів (абрикоси, вишні, груші, сливи, яблука тощо), але і практично з будь-яких інших продуктів.</li>
                        <li>Мінімальні витрати за рахунок використання альтернативного палива.</li>
                        <li>Мінімальні витрати на паливо завдяки раціональній тепловій схемі сушарки та надійної теплоізоляції корпусу.</li>
                    </ul>
                    <TableContainer component={Paper} className={classes.contentTable}>
 
                    <Table aria-label="simple table">
                        <TableBody>

                            <TableRow><TableCell>Характеристика</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow><TableCell>Номінальна напруга живлення:</TableCell>
                                <TableCell>220В, 50Гц</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Потужність, не більше</TableCell>
                                <TableCell>0,5 кВт</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Тип нагрівача:</TableCell>
                                <TableCell>Піч на дровах</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Габарити</TableCell>
                                <TableCell>2300х1160х1300 мм.</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Маса</TableCell>
                                <TableCell>340 кг.</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Максимальна температура в робочій камері</TableCell>
                                <TableCell>65 ± 5 ° С</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Час розігріву порожньої камери до 65°С</TableCell>
                                <TableCell>40 хв</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Регулювання режимів сушіння</TableCell>
                                <TableCell>ручне</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Тривалість циклу сушіння</TableCell>
                                <TableCell>в залежності від обраного виду сировини</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Максимальне завантаження сировиною:</TableCell>
                                <TableCell>- по зерняткових і овочам 	100 кг</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Тривалість циклу сушіння</TableCell>
                                <TableCell>- по зерняткових і овочам 	4-10 години</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                    
                    </TableContainer>
 
                </Container>
                <SectionProducts />
            </Container>

        </div>
    )
}