import React, { Component } from 'react'
import {Helmet} from "react-helmet";
import Hero from '../../components/hero'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ImageWithText from './sectionImageAndText'
import HomeContacts from './sectionContacts'
import SectionImage from './sectionImage'
import SectionProducts from './sectionProducts'
import SectionTitle from './sectionTitle'
// fonts
import fonts from '../../assets/style'
//colors
import colors from '../../assets/colors'
// images for background
import BgHero from '../../assets/images/інпрофіт-виготовлення-та-модернізація-зерносушарок.jpg'
import BgHeroPre from '../../assets/images/інпрофіт-виготовлення-та-модернізація-зерносушарок_w_200.jpg'
import BgImageForContacts from '../../assets/images/інноваційні-системи-та-технології-зерносушарок.jpg'
import image1 from '../../assets/images/зерносушка-колонна.png'
import image2 from '../../assets/images/теплогенератор.png'

const styles = theme => ({
    root: {
        fontWeight: fonts.fontWeightRegular,  
    },
    h4Title : {
        textAlign: 'center',
        padding: '2rem 0',
        fontSize: '1.5rem',
        color: colors.mainDark,
    },
})

const section1Content = [
    {name:'Потужність',data1:'5-30 т/год'},
    {name:'Кількість модулів',data1:'1-3'},
    {name:'Загальна ємкість одночасної загрузки (т)',data1:'11,2 - 43,2'},
    {name:'Середні витрати природного газу (л)',data1:'100 - 380'},
]
const section2Content = [
    {name:'Модель',data1:'СК4-2-7',data2:'СК4-2-9'},
    {name:'Температура сушки(C)',data1:'50-100',data2:'50-100'},
    {name:'Продуктивність сушіння вологої сировини (кг/год)',data1:'2000-2400',data2:'2800-3200'},
    {name:'Загальна потужність (КВТ)',data1:'35,5',data2:'42,5'},
]

export class Home extends Component {
    render() {
        const {classes} = this.props
        return (
            <div className={classes.root}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>INPROFIT - Виготовлення металоконструкцій під замовлення.</title>
                    <link rel="canonical" href="https://inprofit.com.ua" />
                    <meta name="description" content="Виготовлення та монтаж зерносушарок, індивідуальних нестандартних металоконструкцій під замовлення в Україні." />
                </Helmet>
                <Hero 
                    h1Data={'ІНПРОФІТ – Ваш надійний партнер'}
                    h2Data={'Виготовлення, модернізація, оптимізація систем сушіння с/г продукції'}
                    bgImage={BgHero}
                    bgImagePre={BgHeroPre}
                />
                <ImageWithText 
                    contentData={section1Content}
                    title="Колонна зерносушка"
                    image={image1}
                    url={`/kolonna-zernosusharka`}/>
                <ImageWithText 
                    imageOrder={2}
                    contentData={section2Content}
                    title="Теплогенератори"
                    image={image2}
                    url={`/teplogenerator`}/>
                <SectionImage />
                <SectionTitle />
                <SectionProducts />

                {/*<h4 className={classes.h4Title}>Все що Вам потрібно зробити це просто зателефонувати Нам.</h4>*/}

                <HomeContacts bgImage={BgImageForContacts}/>
            </div>
        )
    }
}

Home.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Home)
