import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    "@keyframes middle": {
        "0%": {opacity: 1,},
        "25%": {opacity: 1,},
        "40%": {opacity: 1,},
        "65%": {opacity: 0,},
        "100%": {opacity: 0,}
    },
    "@keyframes last": {
        "0%": {opacity: 1,},
        "25%": {opacity: 1,},
        "50%": {opacity: 0,},
        "75%": {opacity: 0,},
        "100%": {opacity: 0,}
    },
    "@keyframes first": {
        "0%": {opacity: 1,},
        "25%": {opacity: 1,},
        "50%": {opacity: 1,},
        "75%": {opacity: 0,},
        "100%": {opacity: 0,}
    },
    animated: {
        '& .last':{
            animation: `$last infinite 3s ease-in`,
            animationDelay: " -.2s",
        },
        '& .first':{
            animation: `$first infinite 3s ease-in`,
        animationDelay: " -.8s",
        },
        '& .middle':{
            animation: `$middle infinite 3s ease-in`,
            animationDelay: "-.6s",
        }
    },
    lastElement:{
        fill:'#BFDFFC',
        opacity: 0,
    },
    firstElement: {
        fill:'#2196f3',
        opacity: 0,
    },
    middleElement: {
        fill:'#6FBBEF',
        opacity: 0,
    },
    mainElement: {
        fill:'#6fbbef'
    },
}))
     
function LogoIcon(props) {
    const { width, animation } = props
    const widthValue = width ? `${width}px` : `50px`
    const classes = useStyles()
    return (
        <svg width={widthValue} viewBox="0 0 176.17 172.36" className={animation && classes.animated}>
            <path className={`${classes.lastElement} last`} d="M170.21,42.89,128.92,9.83A28.53,28.53,0,0,0,105.8,4.59a26.74,26.74,0,0,1,11.57,5.24l41.29,33.06a19.66,19.66,0,0,1,7.79,15.27v64a19.66,19.66,0,0,1-7.79,15.27l-41.29,33.06a26.86,26.86,0,0,1-11.57,5.24c8,1.58,16.75-.15,23.12-5.24l41.29-33.06A19.66,19.66,0,0,0,178,122.2v-64A19.66,19.66,0,0,0,170.21,42.89Z" transform="translate(-1.83 -4)"/>
            <path className={`${classes.middleElement} middle`} d="M150,42.89,108.7,9.83c-6.55-5.25-15.64-6.94-23.84-5.1a26.53,26.53,0,0,1,10.85,5.1L137,42.89a19.66,19.66,0,0,1,7.79,15.27v64A19.66,19.66,0,0,1,137,137.47L95.71,170.53a26.79,26.79,0,0,1-10.85,5.1c8.2,1.83,17.29.14,23.84-5.1L150,137.47a19.63,19.63,0,0,0,7.79-15.27v-64A19.63,19.63,0,0,0,150,42.89Z" transform="translate(-1.83 -4)" />
            <path className={`${classes.firstElement} first`} d="M50.91,9.83,9.62,42.89A19.63,19.63,0,0,0,1.83,58.16v64a19.63,19.63,0,0,0,7.79,15.27l41.29,33.06c9.71,7.77,25,7.77,34.69,0l41.29-33.06a19.66,19.66,0,0,0,7.79-15.27v-64a19.66,19.66,0,0,0-7.79-15.27L85.6,9.83C75.89,2.06,60.62,2.06,50.91,9.83ZM99.44,127.12,85.76,138c-9.7,7.71-24.89,7.71-34.58,0L37.49,127.12a19.67,19.67,0,0,1-7.84-15.32V68.17a19.66,19.66,0,0,1,7.84-15.32L51.18,42c9.69-7.71,24.88-7.71,34.58,0L99.44,52.85a19.66,19.66,0,0,1,7.84,15.32V111.8A19.67,19.67,0,0,1,99.44,127.12Z" transform="translate(-1.83 -4)"/>
            <path className={classes.mainElement} d="M49.32,55.2A12.16,12.16,0,0,1,54.67,45q5.34-4,13.87-4t13.87,4a12.36,12.36,0,0,1,0,20.36q-5.34,4-13.87,4t-13.87-4A12.15,12.15,0,0,1,49.32,55.2Zm23,81.93h-8c-7.4,0-13.38-5.06-13.33-11.26l.29-40.32c0-6.16,6-11.12,13.33-11.12h8.06c7.4,0,13.38,5.06,13.33,11.27L85.68,126C85.62,132.16,79.67,137.13,72.35,137.13Z" transform="translate(-1.83 -4)"/>
        </svg>
    )
}

export default LogoIcon
