import React from 'react'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import MainImage from '../../assets/images/теплогенератор.jpg'

//other products
import SectionProducts from '../Home/sectionProducts'

import {Helmet} from 'react-helmet'

export default function Teplogenerator() {
    const classes = useStyles()
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Теплогенератор на щепе для Зерносушилки – INPROFIT</title>
                <link rel="canonical" href="https://inprofit.com.ua/teplogenerator" />
                <meta name="description" content="Основною перевагою теплогенераторів INPROFIT є робота на альтернативних видах палива (відходи переробки зерна, тріска, пелети, брикети)." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr}/>
                <div className={classes.imageAndTitle}>
                    <img className={classes.mainImage} src={MainImage} alt="Теплогенератор від ІнпроФіт. Модернізація зерносушарок."/>
                    <h1 className={classes.productTitle}>Теплогенератор</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                <p>Багато українських компаній вже пропонують високопродуктивні теплогенератори і теплообмінники, 
                    працюючі на альтернативних видах палива.</p>
                <p>Теплогенератор - устаткування призначене для отримання теплової енергії за допомогою спалювання 
                    різних видів палива.</p>
                <p>Оснащення зерносушильного комплексу теплогенератором виробництва компанії «ІНПРОФІТ» дає 
                    істотні переваги, так-як одним з основних механізмів теплогенератора є система рівномірного 
                    подання палива в камеру горіння.</p>
                <p>За рахунок її впровадження забезпечується стабільна робота зерносушарки, що позитивно впливає 
                    на тривалість і якість сушки зерна.</p>
                <p>Теплообмінник  дозволяє забезпечити зерносушарку повітрям, прогрітим до температури 120 <sub>0</sub>С, 
                    при цьому виключається зіткнення паливних газів і теплового агента сушіння.</p>
                <p>Теплогенератор складається з наступних частин:</p>
                <ul>
                    <li>блок подачі палива;</li>
                    <li>предтопок (камера згорання) з нагнітаючим вентилятором;</li>
                    <li>блок золовидалення і чищення;</li>
                    <li>блок димоходів з димососом;</li>
                    <li>вертикальний трубчатий теплообмінник;</li>
                    <li>система управління;</li>
                    <li>запобіжний клапан.</li>
                </ul>
                <p>Паливо подається в камеру згорання автоматично за допомогою шнеку (тверде паливо невеликих фракцій), 
                    або в ручну - через спеціальний люк для завантаження дрів, брикетів. Автоматична система подання 
                    палива є кращим варіантом, так як забезпечує стійкий температурний процес горіння, що позитивно 
                    позначається на виробничих процесах в цілому, а також значно економить паливо. Предтопок 
                    спроектований таким чином, що нагнітаючий вентилятор створює постійний приплив повітря для 
                    горіння палива в камері згорання, а також для допалювання димогарних газів.</p>
                <p>А це в свою чергу:</p>
                <ul>
                    <li>покращує процес горіння палива, а значить і його економію;</li>
                    <li>зменшує шкідливі викиди в атмосферу;</li>
                    <li>збільшує температуру горіння;</li>
                    <li>забезпечує стабільну роботу теплогенератора.</li>
                </ul>
                <p>Теплообмінник являє собою вертикальну трубчату конструкцію, виготовлену з нержавіючої сталі, перша 
                    камера якого додатково захищена вогнетривким матеріалом від прогоряння. В конструкції теплообмінника 
                    передбачено можливість очистки стінок від продуктів горіння та видалення золи на зовню.</p>
                <p>Оснащення теплогенератора системою управління дозволяє ретельніше стежити за температурним режимом на 
                    виході з теплообмінника, а також оперативно реагувати на аварійні ситуації.</p>

                </Container>
                <SectionProducts/>
            </Container>
            
        </div>
    )
}
