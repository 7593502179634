import React from 'react'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import MainImage from '../../assets/images/конвеєрна-зерносушарка.jpg'

//other products
import SectionProducts from '../Home/sectionProducts'

import { Helmet } from 'react-helmet'

export default function KonveernaSusharka() {
    const classes = useStyles()
    return (
        <div>
           <Helmet>
                <meta charSet="utf-8" />
                <title>Сушарка конвеєрна в Україні – INPROFIT</title>
                <link rel="canonical" href="https://inprofit.com.ua/konveerna-zernosusharka" />
                <meta name="description" content="Сушильна камера конвеєрної сушарки виробництва «ІНПРОФІТ» виготовлена з нержавіючої сталі і може використовуватись для сушки  листків та квіток коноплі." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr} />
                <div className={classes.imageAndTitle}>
                <img className={classes.mainImage} src={MainImage} alt="Конвеєрна зерносушарка від ІнпроФіт Україна"/>
                    <h1 className={classes.productTitle}>Сушарка конвеєрна</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                    <p>Сушарка конвеєрна застосовується для сушки  квіток коноплі та листків. Але також 
                        може використовуватись для сушки і інших трав’яних рослин та фруктів. В якості 
                        теплоносія використовується гаряче повітря. Для транспортування продукту в 
                        сушильній камері застосовується плетені стрічки з нержавіючої сталі. Залежно від 
                        вимог замовника вибирається тип стрічки і розмір плетіння. Бічна частина стрічки 
                        закріплена до приводних ланцюгів. Приводні ланцюги забезпечують надійну і тривалу 
                        експлуатацію сушильної машини. Усередині сушильна камера виготовлена з нержавіючої 
                        сталі. Зовнішня поверхня обшита листами оцинкованої сталі. Між шарами металу 
                        знаходиться шар довговічного мінерального утеплювача.</p>
                    
                    <p>Сушарка для коноплі складається з наступних частин:</p>
                    <ul>
                        <li>Конвеєр завантажувальний</li>
                        <li>Конвеєр вивантажувальний</li>
                        <li>Сушарка конвеєрна</li>
                        <li>Теплообмінник</li>
                        <li>Нагнітаючий вентилятор</li>
                        <li>Предтопок</li>
                    </ul>
                    <p>
                        Принцип роботи сушарки.
                    </p>
                    <ul>
                        <li>
                            вологий матеріал поміщається на конвеєрну стрічку за допомогою завантажувального конвеєру, який рівномірно роз приділяє матеріал.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            далі матеріал проходить конвеєрним стрічкам, де продувається теплим повітрям .
                        </li>
                    </ul>
                    <ul>
                        <li>
                            тепле повітря надходить в сушарку з теплообмінника за допомогою нагнітаючого вентилятору. Це гаряче повітря входить в сушильну камеру в нижній частині сушарки і рівномірно проходить через нержавіючу сітку, з якої виготовлена конвеєрна стрічка.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            висушена суха маса вивантажується з сушарки за допомогою конвеєру.
                        </li>
                    </ul>
                    <p>
                        Переваги сушарки для канабісу компанії «ІНПРОФІТ»:
                    </p>
                    <ul>
                        <li>
                            Теплогенератор сушарки може працювати з різними видами палива (газ, дизель, дрова, відходи тощо), обирає клієнт;
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Безперервний принцип роботи економить час та витрати людських ресурсів;
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Легко монтується, не потрібно створювати спеціальної основи.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            Сушарка зібрана з використанням електричних компонентів відомих світових виробників. Частотні перетворювачі - Lenze, АВВ (Німеччина) електричні елементи - АВВ, Eaton, Shneider electric, Приводи - Motovario (Італія)
                        </li>
                    </ul>

                    <TableContainer component={Paper} className={classes.contentTable}>
 
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Модель</TableCell>
                                <TableCell align="center">СК4-2-7</TableCell>
                                <TableCell align="center">СК4-2-9</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            <TableRow><TableCell >Ширина стрічки (м)</TableCell>
                                <TableCell align="center">2</TableCell>
                                <TableCell align="center">2</TableCell>
                            </TableRow>
                            <TableRow><TableCell >Довжина сушильної камери(м)</TableCell>
                                <TableCell align="center">7</TableCell>
                                <TableCell align="center">9</TableCell>
                            </TableRow>
                            <TableRow><TableCell >Товщина шару матеріалу (мм)</TableCell>
                                <TableCell align="center" colSpan={2}>10-80</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Температура сушки(C)</TableCell>
                                <TableCell align="center" colSpan={2}>50-100</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Тиск пару (МПА)</TableCell>
                                <TableCell align="center" colSpan={2}>0,2-0,8</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Площа теплообміну (м²)</TableCell>
                                <TableCell align="center">50,5</TableCell>
                                <TableCell align="center">65</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Продуктивність сушіння вологої сировини (кг/год)</TableCell>
                                <TableCell align="center">2000-2400</TableCell>
                                <TableCell align="center">2800-3200</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Потужність (КВТ)</TableCell>
                                <TableCell align="center">30</TableCell>
                                <TableCell align="center">37</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Загальна потужність (КВТ)</TableCell>
                                <TableCell align="center">35,5</TableCell>
                                <TableCell align="center">24,5</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Габаритні розміри (ДхШхВ)</TableCell>
                                <TableCell align="center">8,5x2,6x2,7</TableCell>
                                <TableCell align="center">10,5x2,6x2,7</TableCell>
                            </TableRow>
                            <TableRow><TableCell>Вага (кг)</TableCell>
                                <TableCell align="center">5400</TableCell>
                                <TableCell align="center">6550</TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                    
                    </TableContainer>
 
                </Container>
                <SectionProducts />
            </Container>

        </div>
    )
}