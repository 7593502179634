import React from 'react'
import Container from '@material-ui/core/Container'
import { useStyles } from './styles'
import MainImage from '../../assets/images/модернізація-зерносушарок.jpg'

//other products
import SectionProducts from '../Home/sectionProducts'

import {Helmet} from 'react-helmet'

export default function Modernizaciya() {
    const classes = useStyles()
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Модернізація зерносушарок в Україні (альтернативні види палива)</title>
                <link rel="canonical" href="https://inprofit.com.ua/modernizaciya" />
                <meta name="description" content="Маючи досвід роботи по виробництву зерносушарок  ми пропонуємо повний перелік послуг по переобладнанню існуючих зерносушарок на альтернативні види палива." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr}/>
                <div className={classes.imageAndTitle}>
                    <img className={classes.mainImage} src={MainImage} alt="Модернізація зерносушарок Україна - ІнпроФіт"/>
                    <h1 className={classes.productTitle}>Модернізація зерносушарок</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                <p>Наша компанія «ІНПРОФІТ» окрім розробки та виготовлення зерносушарок  займається 
                    переобладнанням зерносушарок для роботи на альтернативних видах палива.</p>
                <p>У зв'язку із складною економічною ситуацією в нашій країні, а також збільшенням 
                    вартості традиційних теплоносіїв (газ, дизельне паливо) переобладнання зерносушарок 
                    на альтернативні види палива стає для господарств все більш актуальним.</p>
                <p>Найчастіше альтернативним паливом називають відходи деревообробної промисловості 
                    (тирса, тріска, кора), відходи переробки рослинної сільськогосподарської продукції 
                    (лушпиння соняшнику, подрібнені стебла сухої кукурудзи та ін.),  а також паливні 
                    гранули (пелети) або брикети.</p>
                <p>Маючи досвід роботи по виробництву і модернізації устаткування для переробки зерна 
                    ми пропонуємо повний перелік послуг, пов'язаний з переобладнанням існуючих 
                    зерносушарок на альтернативні види палива.</p>
                <p>Ми виконуємо наступні види робіт :</p>
                <ul>
                    <li>проектування установки;</li>
                    <li>виробництво необхідного устаткування (теплогенератор, теплообмінник);</li>
                    <li>монтаж устаткування;</li>
                    <li>проведення пусконалагоджувальних робіт.</li>
                </ul>
                <p>Особлива увага при проектуванні устаткування для переобладнання зерносушарки на 
                    альтернативне паливо була приділена забезпеченні простоти в обслуговуванні, а 
                    це у свою чергу значно зменшує час простою установки.</p>
                <p>Наше устаткування забезпечить надійну роботу Вашого підприємства на довгі роки, 
                    істотно понизивши собівартість.</p>
                <p>обмінника, а також оперативно реагувати на аварійні ситуації.</p>

                </Container>
                <SectionProducts/>
            </Container>
            
        </div>
    )
}
