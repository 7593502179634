import React from 'react'
import Container from '@material-ui/core/Container'
import { Helmet } from 'react-helmet'
import { useStyles } from './styles'
import MainImage from '../../assets/images/металоконструкції.png'

//other products
import SectionProducts from '../Home/sectionProducts'

export default function Metalokonstrukciyi() {
    const classes = useStyles()

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>INPROFIT - Виготовлення металоконструкцій під замовлення.</title>
                <link rel="canonical" href="https://inprofit.com.ua/metalokonstrukciyi" />
                <meta name="description" content="Виготовлення та монтаж індивідуальних нестандартних металоконструкцій під замовлення в Україні." />
            </Helmet>
            <Container className={classes.container} size="lg">
                <hr className={classes.hr} />
                <div className={classes.imageAndTitle}>
                    <img className={classes.mainImage} src={MainImage} alt="МеталоконструкціЇ. Виготовлення та встановлення Україна - ІнпроФіт" />
                    <h1 className={classes.productTitle}>Металоконструкції</h1>
                </div>
                <Container size="md" className={classes.contentSection}>
                    <p>
                        Компанія «Інпрофіт» виготовляє різні вироби з металу за індивідуальним 
                        замовленням.
                    </p>
                    <ul>
                        <li>Виготовлення будівельних металоконструкцій: ферми, колони, балки, 
                            арки, сходи, закладні деталі, траверси;</li>
                        <li>Виготовлення металевих каркасів, ангарів, швидкомонтованих 
                            будинків, навісів, контейнерів, козирків;</li>
                        <li>Виготовлення металоконструкцій під замовлення: нестандартні 
                            металоконструкції, металоконструкції по кресленнях;</li>
                        <li>Виготовлення металевих гвинтових сходів, вхідних груп, сходові 
                            та балконні огородження;</li>
                        <li>Виготовлення технологічних металоконструкцій: бункери, бочки, 
                            баки, резервуари, жолоби, силоси;</li>
                        <li>Виготовлення металоконструкцій трубопроводів і повітроводів, циклонів.</li>
                    </ul>

                    <p>
                        Наша компанія займається виготовленням металоконструкцій, виробництвом 
                        міцних і надійних споруд. Проектування металоконструкцій в нашій компанії 
                        здійснюється кваліфікованими і досвідченими фахівцями. Роз’ємні з'єднання 
                        скріплюються за допомогою болтів класу міцності 8,8. Нероз'ємні з'єднання 
                        закріплюються на заклепки, які застосовуються для з'єднання нетовстих листів. 
                        Наше підприємство надає якісні послуги за невисокою ціною. Вартість послуги 
                        залежить від різних чинників, від складності виконуваних робіт.
                    </p>

                </Container>
                <SectionProducts />
            </Container>
        </div>
    )
}