const colors = {
    main: '#2196f3',
    mainLight: '#6ec6ff',
    mainDark: '#0069c0',
    secondary: '#1b5e20',
    secondaryLight: '#4c8c4a',
    secondaryDark: '#003300',
    white: 'white',
    black: 'black',
    grey: '#F8F8F8',
    lightGrey: '#fafafa',
    paleGrey: '#c9c9c9',
    darkGrey: '#D8D8D8',
  };
  
  export default colors;