import React, { Component } from 'react'
import Hero from '../../components/hero'
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'


import BgHero from '../../assets/images/інпрофіт-модернізація-систем-обробки-зерна.jpg'
import BgHeroPre from '../../assets/images/інпрофіт-модернізація-систем-обробки-зерна_w_200.jpg'


const styles = theme => ({
    root: {
        marginTop: '3rem',
        marginBottom: '3rem',
    },
    content: {
        '& > p': {
            fontSize: '.9rem',
        },
    },
})

export class About extends Component {
    render() {
        const {classes} = this.props
        return (
            <div>
                <Hero 
                    h1Data={'Про нас та нашу продукцію'}
                    bgImage={BgHero}
                    bgImagePre={BgHeroPre}
                />
                <Container maxWidth="md" className={classes.root}>
                    <div className={classes.content}>
                        <p>Раніше багато аграріїв для сушки сільськогосподарських культур вибирали зарубіжні мобільні зерносушарки. 
                            Основна причина була у якості технологій і відсутності аналогів на нашому ринку.</p>
                        <p>На сьогоднішній день багатьом вже не досить якості і продуктивності мобільних зерносушарок і вони 
                            переходять на стаціонарні сушарки.</p>
                        <p>При виборі потужного і якісного устаткування фермери зупиняються на шахтних та модульних – 
                            колонних зерносушарках, але вибір виробника вже проходить по інших критеріях.</p>
                        <p>Найбільші виробники закордонних зерносушарок не можу сьогодні конкурувати за цінами на свої технології. 
                        Крім того, основні виробники зерносушарок в Україні за декілька десятиліть змогли вивести якість 
                        устаткування на новий рівень. І сьогодні такі зерносушарки анітрохи не поступаються польським, 
                        французьким і американським зразкам. Якщо врахувати вартість устаткування, запчастин і можливості 
                        компанії по технічному і сервісному обслуговуванню, то український виробник цих зерносушарок виграє за усіма критеріями.</p>
                        <p>Приватне Мале Підприємство"ІНПРОФІТ  пропонує Вам співпрацю на вигідних умовах щодо виготовлення по 
                            Вашому замовленню зерносушарки високої якості, за короткий термін.</p>
                        <p>У комплектації модульної зерносушарки "ІНПРОФІТ" входять елементи і системи (металоконструкції, 
                            теплогенератори, вентилятори), які розроблені конструкторами підприємства. </p>
                        <p>Зерносушарки від «ІНПРОФІТ» призначені для важливого етапу в післяжнивній обробці зерна. 
                            Наше устаткування збільшує термін зберігання урожаю без псування і втрат, адже правильна сушка 
                            виключає самонагрівання, появу плісняви і розмноження комах-шкідників. </p>
                    </div>
                </Container>
            </div>
        )
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(About)
