import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

//colors
import colors from '../../../assets/colors'
//images

const useStyles = makeStyles((theme) => ({
    root:{
        marginTop: '4rem',
        [theme.breakpoints.down('xs')]: {
            marginTop: '2rem',
        },
    },
    title: {
        position: 'relative',
        fontSize: '1.3rem',
        padding:'2rem',
        backgroundColor: colors.main,
        color: colors.white,
        '&:after':{
            content: '""',
            width: '65%',
            height: '3px',
            top: '50%',
            right: '0',
            backgroundColor: colors.white,
            position: 'absolute',
            [theme.breakpoints.down('sm')]: {
                width: '40%',
            },
            [theme.breakpoints.down('xs')]: {
                display:'none',
            },
        },
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
            textAlign:'center',
        },
    },
})) 



export default function SectionTitle(){
    const classes = useStyles()
    return (
            <Container size="lg" className={classes.root}>
                <h4 className={classes.title}>
                    Інша наша продукція
                </h4>
            </Container>
    )
}