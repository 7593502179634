import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

//colors
import colors from '../../../assets/colors'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    imageBlock: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            order: 1,
            width: '100%',
        },
        backgroundColor: colors.mainDark,
        '& svg': {
            width: '100%',
        },
        '& a': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem 0',
        },
    },
    textBlock : {
        width: '50%',
        padding: '2rem',
        order: 1,
        [theme.breakpoints.down('sm')]: {
            order: 2,
            width: 'auto',
            padding: '1rem',
        },
    },
    ctaButton: {
        borderColor: colors.secondary,
        color: colors.secondary,
        marginTop: '2rem',
        padding: '.5rem 1.5rem',
        float: 'right',
    },
    sectionTitle: {
        color: colors.main,
        textAlign: 'center',
    },
}))

export default function ImageWithText(props) {
    const {imageOrder = 1, contentData = [], title, image, url} = props
    const classes = useStyles()

    const imageForSection = () => {
        if (image) {
            return (<a href={url}><img src={image} width='80%' alt='sometext'/></a>)
        } else {
            return  (<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300 300">
                        <rect fill="#ddd" width="300" height="300"/>
                    </svg>)
        }
    }

    return (
        <section>
            <Container className={classes.container}>
                <div className={classes.imageBlock} style={{ order:  imageOrder}}>
                    {imageForSection()}
                </div>
                <div className={classes.textBlock}>
                    <h3 className={classes.sectionTitle}>{title}</h3>
                    <Table>
                        <TableBody>
                            {contentData.map((row) => (
                                <TableRow key={new Date().getTime() + row.name}>
                                    <TableCell style={{maxWidth: '150px'}} component="th" scope="row">{row.name}</TableCell>
                                    <TableCell align="center"> {row.data1} </TableCell>
                                    {row.data2 && (<TableCell align="center"> {row.data2} </TableCell>)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button href={url} variant="outlined" className={classes.ctaButton}>Більше&nbsp;&nbsp;&nbsp;</Button>
                </div>
            </Container>
        </section>
    )
}
