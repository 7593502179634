import React, { Component } from 'react'
import Hero from '../../components/hero'
import AccordionItem from './components/accordion'
import Container from '@material-ui/core/Container';

import BgHero from '../../assets/images/інпрофіт-виготовлення-та-модернізація-зерносушарок.jpg'
import BgHeroPre from '../../assets/images/інпрофіт-виготовлення-та-модернізація-зерносушарок_w_200.jpg'
import question3FirstImage from '../../assets/images/зерносушарка-інпрофіт.jpg'
import question3SecondImage from '../../assets/images/принцип-роботи-зерносушарки-інпрофіт.jpg'

const FAQData = [
    {
        id:1,
        question:'Що являє собою модульна зерносушарка?',
        answer: `<p>Модульними зерносушарки називають через їх конструктивної особливості: зерносушарка 
                    складається з модулів-секцій, кількість яких залежить від побажань споживача і визначає продуктивність.</p>
                    <p>Принцип роботи зерносушарки модульної можна спрощено описати так: </p>
                    <p>Зерно подається через завантажувальну воронку у верхній частині зерносушарки на шнек, що 
                    розподіляє сипучий продукт і по черзі завантажує колони; Для нагнітання повітря використовуються 
                    одно або двоколесні відцентрові вентилятори. Багатоступенева функція контролю роботи і захисту 
                    зерносушарки здійснюється за допомогою виносного пульта управління. Автоматика контролює 
                    рівномірність подачі повітря і відповідність його температурних показників. Просушені зернові 
                    вивантажуються з зерносушарки за допомогою шнеку, що транспортує сухе зерно за межі зерносушки.</p>`
    },
    {
        id:2,
        question:'Як збільшити продуктивність модульної зерносушарки?',
        answer: `<p>Нарощування продуктивності модульної зерносушарки здійснюється завдяки збільшенню в їхньому складі 
                    кількості зернових колон або встановлення додаткового верхнього модуля. Проектна максимальна продуктивність 
                    на сушінні кукурудзи одно модульної зерносушарки в режимі «нагрівання-охолодження» (від 24 % до 14 %) 
                    становить – 17 т/год., а двомодульної – 35 т/год. відповідно.</p>`
    },
    {
        id:3,
        question:'В якому режимі може працювати модульна зерносушарка?',
        answer: `<p>Модульна зерносушарка може працювати в трьох режимах:</p>
                    <p><b>Перший режим</b> – нагрів та охолодження зерна безпосередньо в сушарці. 
                    Сушарка працює в порційному режимі – спочатку заповнюється вологим зерном, і продувається нагрітим повітрям 
                    певний час, поки не буде досягнуто потрібного рівня вологості. Після чого подача теплого повітря припиняється 
                    і зерно продувається атмосферним повітрям на протязі 20-25 хвилин.</p> 
                    <p>При такому режимі сушіння зерно, що виходить з сушарки вже висушене та охолоджене. 
                    Далі цикл знову повторюється.</p>
                    <p><b>Другий режим</b> – тільки нагрів зерна, без його охолодження в сушарці в поточному режимі. 
                    В цьому режимі зерно в сушарці тільки нагрівається, і коли буде досягнуто потрібного рівня вологості 
                    включається система вигрузки та одночасної загрузки нового вологого зерна. Процес йде безперервний, за 
                    рахунок чого збільшується продуктивність в 1,5 рази, в порівнянні з першим режимом, але й  збільшуються 
                    витрати енергоносіїв та витрати на додаткове обладнання.</p>
                    <div><img style="max-width: 500px; margin: 20px auto; display: block;" src="${question3FirstImage}" alt="Нагрів та охолодження зерна безпосередньо в сушарці" /></div>
                    <p><b>Третій режим</b> - нагрів та охолодження зерна в сушарці в поточному режимі тобто, одночасно здійснюється 
                    вигрузка висушеного і охолодженого зерна та автоматична дозагрузка зерносушарки сирим зерном. 
                    Сушарка розділена на дві камери (верхню та нижню), та має два вентилятори. Перший вентилятор - основний - 
                    нагнітає гаряче повітря в верхню камеру та другий вентилятор - охолодження - нагнітає холодне повітря в нижню камеру.
                    Поки зерно проходить зону верхньої камери воно нагрівається гарячим повітрям від теплогенератора та досягає 
                    заданого рівня вологості. Після чого зерно проходить зону нижньої камери, де йде продувка зерна холодним повітрям, 
                    що нагнітається з зовнішнього середовища вентилятором. При такому режимі сушіння зерно, що виходить з сушарки вже 
                    висушене та охолоджене.</p>
                    <div><img style="max-width: 500px; margin: 20px auto; display: block;"src="${question3SecondImage}" alt="Нагрів та охолодження зерна в сушарці в поточному режимі" /></div>
                    <p>Порівняно з другим режимом роботи, продуктивність зерносушарки зменшується на 25-30%.</p>`
    },
    {
        id:4,
        question:'Чи варто переобладнувати зерносушарку на газу на теплогенератор на дровах?',
        answer: `<p>Теплогенератор на твердому паливі - це величезна економія. Тільки уявіть, що Ваші витрати на експлуатацію зерносушарки 
                    скоротяться в 2, а то і 3 рази! Ви будете мати можливість опалювати свою зерносушарку будь-яким видом твердого палива, в тому 
                    числі і дровами. Але не варто забувати, що теплогенератор на твердому паливі обов’язково повинен бути з теплообмінником, в 
                    іншому випадку висушене зерно буде з примішкою продуктів горіння.</p>
                    <p>Ми виготовляємо теплогенератори, обладнані вертикальними трубчастими теплообмінниками, виготовленими з нержавіючої 
                    сталі, із блоком захисту від прогоряння.</p>
                    <p> Конструкція теплогенератора та приєднувальних елементів модифікуються під умови конкретної зерносушарки або місце 
                    розташування комплексу.Завдяки системі автоматизованої подачі палива підтримується стабільна температура всередині 
                    теплогенератора.</p>`
    }
]

const FAQContent = () => {

    return (
        <Container maxWidth="md">
           {FAQData.map((el) => <AccordionItem key={el.id} question={el.question} answer={el.answer}/>)}
        </Container>
    )
}

class FAQ extends Component {
    render() {
        return (
            <div>
                <Hero 
                    h1Data={'Питання та відповіді'}
                    bgImage={BgHero}
                    bgImagePre={BgHeroPre}
                />
                < FAQContent />
            </div>
        )
    }
}

export default FAQ